exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carriers-tsx": () => import("./../../../src/pages/carriers.tsx" /* webpackChunkName: "component---src-pages-carriers-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-products-policy-tsx": () => import("./../../../src/pages/legal/products-policy.tsx" /* webpackChunkName: "component---src-pages-legal-products-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-use-case-tsx": () => import("./../../../src/templates/use-case.tsx" /* webpackChunkName: "component---src-templates-use-case-tsx" */)
}

